<template>
  <div class="accordion-item" :id="collapseId">
    <div
      class="accordion-item-headline"
      :style="bgColor"
      @click="scrollCollapseToElement(collapseId)"
    >
      <h3 class="accordion-item-text">
        <i18n :path="headline"></i18n>
      </h3>
      <img
        :class="[
          this.$store.getters.getCollapseStateOfId(collapseId)
            ? 'accordion-item-arrow-rotate'
            : 'accordion-item-arrow',
        ]"
        :src="arrow"
      />
    </div>
    <div
      ref="accordionContent"
      :style="[
        this.$store.getters.getCollapseStateOfId(collapseId)
          ? { maxHeight: '5000px' }
          : {},
      ]"
      class="accordion-item-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { scrollToElementById } from "@/services/scroll.js";

export default {
  props: {
    headline: String,
    backgroundColor: String,
    collapseId: String,
  },
  data() {
    return {
      computedHeight: 0,
    };
  },
  methods: {
    initialContentHeight() {
      this.$refs["accordionContent"].style.maxHeight = 0;

      this.computedHeight = window.getComputedStyle(
        this.$refs["accordionContent"],
      ).height;

      this.$refs["accordionContent"].style.maxHeight = 0;
    },
    scrollCollapseToElement(id) {
      const opendId = this.$store.getters.getOpenedId ?? id;
      scrollToElementById(opendId).then(() =>
        this.$store.commit("setCollapseStateToTrue", id),
      );
    },
  },
  computed: {
    bgColor() {
      return {
        "background-color": this.backgroundColor,
      };
    },
    arrow() {
      return require("@/assets/svg/accordion-arrow.svg");
    },
  },
  mounted() {
    this.initialContentHeight();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
.accordion-item {
  cursor: pointer;

  &-headline {
    @include ts-accordion-headline();
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
  }

  &-text {
    color: $text-light;
    text-align: center;
  }

  &-arrow {
    height: 18px;
    width: 18x;
    margin-left: 20px;
    transition: all 0.3s ease-in-out;

    &-rotate {
      height: 18px;
      width: 18x;
      margin-left: 20px;
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out;

      @include breakpoint(xs) {
        height: 14px;
        width: 14px;
        margin-left: 16px;
        transform: rotate(90deg);
        transition: all 0.3s ease-in-out;
      }
    }

    @include breakpoint(xs) {
      height: 14px;
      width: 14px;
      margin-left: 16px;
      transition: all 0.3s ease-in-out;
    }
  }

  &-content {
    height: auto;
    overflow: hidden;
    transition: 0.3s;
    max-height: 0;
    transition: all 0.3s ease-in-out;
  }
}
</style>
