<template>
  <section id="accordion" class="accordion">
    <div class="accordion-text">
      <h2 class="accordion-text-headline">
        <i18n path="accordion_header"></i18n>
      </h2>
      <p class="accordion-text-subheadline">
        <i18n path="accordion_paragraph"></i18n>
      </p>
    </div>
    <div>
      <accordion-item
        v-for="section in sections"
        :key="section.headline"
        :id="section.id"
        :headline="section.headline"
        :backgroundColor="section.bgColor"
        :collapseId="section.id"
      >
        <component :is="section.component"></component>
      </accordion-item>
    </div>
  </section>
</template>

<script>
import AccordionItem from "@/components/AccordionItem.vue";
import ObstructingSection from "./ObstructingSection.vue";
import PulmonarySection from "./PulmonarySection.vue";
import InterstitialSection from "./InterstitialSection.vue";
import PeripheralSection from "./PeripheralSection.vue";
import AspirationSection from "./AspirationSection.vue";
import BenignSection from "./BenignSection.vue";
import ExsudativeSection from "./ExsudativeSection.vue";
import { scrollToElementById } from "@/services/scroll.js";

export default {
  components: {
    AccordionItem,
    ObstructingSection,
    PulmonarySection,
    InterstitialSection,
    PeripheralSection,
    AspirationSection,
    BenignSection,
    ExsudativeSection,
  },
  data() {
    return {
      sections: [
        {
          id: "obstruction",
          component: "ObstructingSection",
          headline: "accordion_headline1",
          bgColor: "#00ac6c",
        },
        {
          id: "pulmonary",
          component: "PulmonarySection",
          headline: "accordion_headline2",
          bgColor: "#00abbd",
        },
        {
          id: "interstitial",
          component: "InterstitialSection",
          headline: "accordion_headline3",
          bgColor: "#7fcbae",
        },
        {
          id: "peripheral",
          component: "PeripheralSection",
          headline: "accordion_headline4",
          bgColor: "#8BC751",
        },
        {
          id: "aspiration",
          component: "AspirationSection",
          headline: "accordion_headline5",
          bgColor: "#00afad",
        },
        {
          id: "benign",
          component: "BenignSection",
          headline: "accordion_headline6",
          bgColor: "#bfd730",
        },
        {
          id: "exsudative",
          component: "ExsudativeSection",
          headline: "accordion_headline7",
          bgColor: "#4eb857",
        },
      ],
    };
  },
  watch: {
    "$store.state.accordionSectionCollapse.openedId": function(
      newValue,
    ) {
      setTimeout(() => scrollToElementById(newValue), 500);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
.accordion {
  &-text {
    @include padding-h(60px);
    @include padding-v(32px);
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;
    &-headline {
      @include ts-section-headline();
      color: $text_dark;
      margin-bottom: 20px;
      @include breakpoint(s) {
        margin-bottom: 15px;
      }
      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }
    &-subheadline {
      @include ts-section-subheadline();
      color: $text_dark;
    }
  }
}
</style>
