import axios from "axios";
import { successHandler, errorHandler } from "./resultHandler";
import querystring from "query-string";

export function postContactForm(name, company, email, country, city, message) {
  let body = {
    name,
    email,
    company,
    city,
    country,
    message
  };

  return axios
    .post("/php/contact.php", querystring.stringify(body), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then(successHandler())
    .catch(errorHandler({}));
}

export function getPdfByUrl(url) {
  const encodedUrl = encodeURIComponent(url);
  return fetch(`/php/downloadPdf.php?pdfUrl=${encodedUrl}`).then(
    response => {
      if (response.status >= 400) {
        throw new Error(
          "Error: HTTP-GET " +
          response.status +
          " " +
          response.statusText +
          " " +
          url
        );
      }

      return response;
    }
  );
}

export function getUrlHeaders(url) {
  return axios
    .get(
      "/php/getPdfMetadata.php",
      { params: { pdfUrl: url } },
      {
        responseType: "Array",
        headers: {}
      }
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      //eslint-disable-next-line
      console.log(error);
    });
}

export function getVideoVersions(videoID) {
  return axios
    .get(
      "/php/getVimeoMetadata.php",
      { params: { videoId: videoID } },
      {
        responseType: "Array",
        headers: {}
      }
    )

    .then(response => {
      return {
        modifyingDate: response.data.timestamp,
        downloadUrl: response.data.downloadUrl
      };
    })
    .catch(error => console.log(error));
}

export async function isOnline() {
  try {
    const response = await fetch("/php/alive.php");
    return response.status === 200;
  } catch (e) {
    return false;
  }
}

export async function offlineResourceIsAvailable({ id, key }) {
  try {
    const url = `resource_check?id=${id}&key=${key}`;
    const response = await fetch(url);
    const body = await response.text()
    return body === "ok";
  } catch (e) {
    return false;
  }
}
