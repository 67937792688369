<template>
  <iframe
    class="video-online"
    :class="{ 'video-online--full': isFullScreen }"
    :src="`https://player.vimeo.com/video/${vimeoId}`"
  />
</template>

<script>
export default {
  name: "VideoOnline",
  props: {
    vimeoId: String,
    isFullScreen: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.video-online {
  height: 100%;
  width: 100%;
  &--full {
    object-fit: fill;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border: 0;
  }
}
</style>
