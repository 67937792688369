import Vue from "vue";
import VueAgile from "vue-agile";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import {
  setLanguageParameter
} from "./router/router.extensions";
import store from "./store";
import i18n from "./locales/i18n";
import {
  SchemaSyncHandler
} from "@/services/caching/schemasync";
import {
  languageAdapter
} from "@/services/languageAdapter"
import {
  initializeAnalytics
} from "@/services/analytics";

Vue.config.productionTip = false;
setLanguageParameter(router);
Vue.use(i18n);
Vue.use(VueAgile);

SchemaSyncHandler.sync();

let language = window.location.hash.split("/")[1]
if (!languageAdapter.isSupported(language)) {
  language = languageAdapter.getLanguageOfUser();
  window.location.hash = "#/" + language;
}
i18n.locale = language;
initializeAnalytics();

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");