var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"obstructing-section"},[_c('span',{staticClass:"accordion-title"},[_vm._v(" "+_vm._s(_vm.$t("accordion_obstructing_title"))+" ")]),_c('AccordionVideoContainer',{attrs:{"videoIds":[
      '312685773',
      '440014204',
      '355305179',
      '690961577',
      '696924990',
      '696925135',
      '696925231',
      '214857781' ]}}),_c('ListWithArrows',{attrs:{"entriesLeft":[
      'accordion_obstructing_arrow_list_1',
      'accordion_obstructing_arrow_list_2',
      'accordion_obstructing_arrow_list_3',
      'accordion_obstructing_arrow_list_4' ],"entriesRight":[
      'accordion_obstructing_arrow_list_5',
      'accordion_obstructing_arrow_list_6',
      'accordion_obstructing_arrow_list_7' ]}}),_c('ScientificInformationCardRow',{attrs:{"count":2,"prefix":"accordion_obstructing"}}),_c('ProductsForIntervention',{attrs:{"leftItems":['20402-411', '20402-410', '20402-401', '20402-402'],"leftInformation":[
      'accordion_obstructing_products_information_1',
      'accordion_obstructing_products_information_2',
      'accordion_obstructing_products_information_3',
      'accordion_obstructing_products_information_4',
      'accordion_obstructing_products_information_5' ],"leftButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en',"rightItems":['20132-220', '20132-221'],"rightInformation":[
      'accordion_obstructing_products_information_6',
      'accordion_obstructing_products_information_7',
      'accordion_obstructing_products_information_8' ],"rightButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DFiAPC&language=en'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }