<template>
  <div class="multimedia-grid-item" :style="bgColor">
    <a :href="href">
      <span class="multimedia-grid-item-subheadline">
        <i18n :path="subheadline"></i18n>
      </span>
      <h3 class="multimedia-grid-item-headline">
        <i18n :path="headline"></i18n>
      </h3>
      <img class="multimedia-grid-item-icon" :src="src" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    headline: String,
    subheadline: String,
    href: String,
    src: String,
    backgroundColor: String,
  },
  computed: {
    bgColor() {
      return {
        "background-color": this.backgroundColor,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
.multimedia-grid-item {
  position: relative;
  a {
    display: block;
    padding: 3rem 1.5rem;
  }
  &-headline {
    @include ts-grid-headline();
  }
  &-subheadline {
    @include ts-grid-subheadline();
  }
  &-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
</style>