<template>
  <div class="products-column" :style="rowStyle">
    <div class="product-row" v-for="item in items" :key="item">
      <div class="product-image-container">
        <img class="product-image" :src="getImageName(item)" />
      </div>
      <div class="product-description">
        {{ $t(`product_for_intervention_${item}`) }}
        <br />
        <div class="product-number">{{ `No. ${item}` }}</div>
      </div>
    </div>
    <div v-if="information.length != 0" class="product-information">
      <ul>
        <li v-for="entry in information" :key="entry">
          <img class="arrow" :src="image" />
          <div>{{ $t(entry) }}</div>
        </li>
      </ul>
    </div>
    <a v-if="buttonLink" :href="buttonLink" class="btn" target="_blank">{{
      $t("product_finder")
    }}</a>
  </div>
</template>

<script>
export default {
  name: "ProductsForInterventionColumn",
  props: {
    items: Array,
    information: Array,
    buttonLink: String,
    fullSize: Boolean,
  },
  methods: {
    getImageName: function(item) {
      const filename = this.$t(`product_for_intervention_${item}_image`);
      return this.$store.getters.img_product_intervention(filename);
    },
  },
  computed: {
    image() {
      return require("@/assets/svg/icon-arrow-right-white@1x.svg");
    },
    rowStyle() {
      return this.fullSize ? "width: 100%;" : "";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";
.btn {
  background-color: #f1f1f1;
  color: black;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 16px 30px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  text-align: center;
  width: 50%;
  @include breakpoint(s) {
    margin-bottom: 50px;
  }
  @include breakpoint(xs) {
    margin-bottom: 50px;
    width: 80%;
  }
  box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.2);
  transform: box-shadow .3s;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.4);
  }
}
.arrow {
  width: 1.5rem;
}
.product-information {
  color: white;
  background-color: rgba(69, 115, 98, 0.7);
  padding: 20px;
  line-height: 1.5;
  width: 100%;
  display: flex;
  flex-direction: column;

  ul {
    @include ie_or_edge() {
      flex: inherit;
      width: 100%;
    }
  }

  li {
    padding-bottom: 10px;
    @include flex-h(flex-start, flex-start);
    @include ie_or_edge() {
      width: 100%;
    }
  }
}

.products-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 50%;
  max-width: 750px;
  @include breakpoint(s) {
    width: 80%;
  }
  @include breakpoint(xs) {
    width: 100%;
  }
}
.product-row {
  display: flex;
  flex-direction: row;
  height: 120px;
}
.product-image-container {
  background-color: #00ac6c;
  display: flex;
  width: 35%;
  justify-content: flex-start;
  align-items: center;
}
.product-image {
  width: 80%;
}
.product-description {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  line-height: 1.5;
  width: 65%;
  @include breakpoint(xs) {
    line-height: 1.1;
    font-size: 11pt;
    width: 90%;
  }
  .product-number {
    font-weight: bold;
  }
}
</style>
