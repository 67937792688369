<template>
  <div id="document-title" />
</template>

<script>
import i18n from "@/locales/i18n";
export default {
  mounted() {
    this.setDocumentTitle();
  },
  watch: {
    "$i18n.locale"() {
      this.setDocumentTitle();
    },
  },
  methods: {
    setDocumentTitle() {
      window.document.title = i18n.t("document_title");
    },
  },
};
</script>
