import Vue from "vue";
import Vuex from "vuex";
import {
  createImagesModule
} from "./modules/images";
import {
  createDocumentsModule
} from "./modules/documents";
import {
  createVideosModule
} from "./modules/videos";
import { createAccordionSectionCollapseModule 
} from "./modules/accordionSectionCollapse";
import {
  isIeOrEdge,
  isHttps,
  isNeitherIeNorEdge,
} from "@/services/browser"

Vue.use(Vuex);

const {
  NODE_ENV,
} = process.env;
const isPwaModeActive = NODE_ENV === "production" && isNeitherIeNorEdge && isHttps;
export default new Vuex.Store({
  state: {
    language: "en",
    isPwaModeActive,
    serviceWorkerReady: undefined,
    isIeOrEdge,
    isSettingsModalVisible: false,
    isDownloading: false,
    downloadProgress: 0,
    downloadingError: null,
    hasBackgroundCheckFinished: false,
    isEverythingCached: false,
    failedVimeoVideos: [],
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    serviceWorkerReady(state, value) {
      state.serviceWorkerReady = value;
    },
    setIsSettingsModalVisible(state, value) {
      state.isSettingsModalVisible = value;
    },
    setIsDownloading(state, value) {
      state.isDownloading = value;
    },
    setDownloadProgress(state, value) {
      state.downloadProgress = value;
    },
    setDownloadingError(state, value) {
      state.downloadingError = value;
    },
    setHasBackgroundCheckFinished(state, value) {
      state.hasBackgroundCheckFinished = value;
    },
    addFailedVimeoVideo(state, vimeoId) {
      state.failedVimeoVideos.push(vimeoId);
    }
  },
  actions: {},
  modules: {
    images: createImagesModule(),
    documents: createDocumentsModule(),
    videos: createVideosModule(),
    accordionSectionCollapse: createAccordionSectionCollapseModule(),
  },
});