<template>
  <div class="scientific-explanation-card">
    <div class="title centered">
      <i18n :path="title" />
    </div>
    <div class="subtitle">
      <i18n :path="subtitle" />
    </div>
    <Transition>
      <div :class="[expanded ? 'text' : 'text-collapsed']">
        <i18n :path="text" />
      </div>
    </Transition>
    <div class="subtitle">
      <a :href="$t(href)" target="_blank">
        <i18n :path="scientificPaperReference" />
      </a>
    </div>

    <div class="centered">
      <a v-if="$t(type) === 'Podcast' && expanded" :href="$t(href)" target="_blank">
        <img class="toggle-arrow-collapsed" src="@/assets/svg/Erbe_Icon_Mikrofon-rund@1x.svg" />
      </a>
      <PdfDocument :name="pdfName">
        <a v-if="$t(type) === 'PDF' && expanded" target="_blank">
          <img class="toggle-arrow-collapsed" src="@/assets/svg/Icon-Arrow-download@1x.svg" />
        </a>
      </PdfDocument>
      <img :class="[expanded ? 'toggle-arrow-expanded' : 'toggle-arrow-collapsed']" src="@/assets/svg/Icon-Arrow@1x.svg"
        @click="expanded = !expanded" />
    </div>
  </div>
</template>

<script>
import PdfDocument from './PdfDocument.vue';
export default {
  name: "ScientificInformationCard",
  props: {
    title: String,
    subtitle: String,
    text: String,
    scientificPaperReference: String,
    type: String,
    href: String,
    pdfName: String,
  },
  data() {
    return {
      expanded: false,
      components: {
        PdfDocument,
      }
    };
  },
  components: { PdfDocument }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.subtitle>a:hover {
  text-decoration: underline;
}

.scientific-explanation-card {
  background-color: white;
  padding: 10px;
  width: 280px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 0;
}

.centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.text {
  text-align: left;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: none;
  -webkit-box-orient: vertical;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-height: 50rem;
}

.text-collapsed {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  transition: all 0.3s ease-in-out;
  max-height: 5rem;
  line-height: 1.2;
}

.subtitle {
  font-family: $font_nettoBold;
}

.title {
  font-family: $font_nettoBlack;
}

.toggle-arrow-collapsed {
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.toggle-arrow-expanded {
  height: 1.5rem;
  width: 1.5rem;
  text-align: center;
  transform: rotate(180deg);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
</style>
