var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"interstitial-section"},[_c('span',{staticClass:"accordion-title"},[_vm._v(" "+_vm._s(_vm.$t("accordion_interstitial_title"))+" ")]),_c('AccordionVideoContainer',{attrs:{"videoIds":[
      '352898182',
      '605506909',
      '605506802',
      '440251946',
      '440014204' ]}}),_c('ListWithArrows',{attrs:{"entriesLeft":[
      'accordion_interstitial_arrow_list_1',
      'accordion_interstitial_arrow_list_2' ],"entriesRight":[
      'accordion_interstitial_arrow_list_3',
      'accordion_interstitial_arrow_list_4' ]}}),_c('ScientificInformationCardRow',{attrs:{"count":4,"prefix":"accordion_interstitial"}}),_c('ProductsForIntervention',{attrs:{"leftItems":['20402-410', '20402-401', '20402-402'],"leftInformation":[
      'accordion_interstitial_products_information_1',
      'accordion_interstitial_products_information_2',
      'accordion_interstitial_products_information_3' ],"leftButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }