<template>
  <div class="home-view">
    <Introduction />
    <Testimonials />
    <Accordion />
    <Workstation />
    <Contact />
    <Multimedia />
    <Footer />
  </div>
</template>

<script>
import Introduction from "@/sections/Introduction/IntroductionSection";
import Testimonials from "@/sections/Testimonials/TestimonialsSection";
import Accordion from "@/sections/Accordion/AccordionSection";
import Workstation from "@/sections/Workstation/WorkstationSection";
import Contact from "@/sections/Contact/ContactSection";
import Multimedia from "@/sections/Multimedia/MultimediaSection";
import Footer from "@/sections/Footer/FooterSection";

export default {
  name: "Home",
  components: {
    Introduction,
    Testimonials,
    Accordion,
    Workstation,
    Contact,
    Multimedia,
    Footer,
  },
};
</script>
