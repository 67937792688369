var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exudative-section"},[_c('span',{staticClass:"accordion-title"},[_vm._v(" "+_vm._s(_vm.$t("accordion_exudative_title"))+" ")]),_c('AccordionVideoContainer',{attrs:{"videoIds":['325442598']}}),_c('ListWithArrows',{attrs:{"entriesLeft":[
      'accordion_exudative_arrow_list_1',
      'accordion_exudative_arrow_list_2',
      'accordion_exudative_arrow_list_3' ],"entriesRight":[
      'accordion_exudative_arrow_list_4',
      'accordion_exudative_arrow_list_5' ]}}),_c('ScientificInformationCardRow',{attrs:{"count":2,"prefix":"accordion_exudative"}}),_c('ProductsForIntervention',{attrs:{"leftItems":['20402-411', '20402-410', '20402-401', '20402-402'],"leftInformation":['accordion_exudative_products_information_1'],"leftButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en',"rightItems":['20132-220', '20132-221'],"rightInformation":[
      'accordion_exudative_products_information_2',
      'accordion_exudative_products_information_3',
      'accordion_exudative_products_information_4' ],"rightButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DFiAPC&language=en'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }