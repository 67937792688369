import {
  getUrlHeaders,
  getVideoVersions,
  getPdfByUrl,
  isOnline,
  offlineResourceIsAvailable,
} from "@/services/http/httpService.js";
import { isPwaInstalled } from "@/services/browser";
import i18n from "@/locales/i18n";
import store from "@/store";

function mergeVideosAndPdfs ({ videos, pdfs }) {
  const { createKey, pdfsKey, videosKey } = self.cacheHelper;
  return videos.map(vimeoId => ({
    vimeoId,
    idbKey: createKey(vimeoId, videosKey),
    type: videosKey,
  })).concat(pdfs.map(pdf => ({
    pdf,
    idbKey: createKey(pdf.key, pdfsKey),
    type: pdfsKey,
  })))
}

export function isVideoAvailable(vimeoId) {
  const { videosKey } = self.cacheHelper;
  return offlineResourceIsAvailable({
    id: vimeoId,
    key: videosKey,
  });
}

export function isPdfAvailable(name) {
  const { pdfsKey } = self.cacheHelper;
  return offlineResourceIsAvailable({
    id: name,
    key: pdfsKey,
  });
}

export function createKey(key, type) {
  return self.cacheHelper.createKey(key, type);
}

export async function getAll({ videos, pdfs }) {
  const online = await isOnline();
  if (!online) {
    store.commit("setDownloadingError", "NO INTERNET");
    return;
  }
  store.commit("setIsDownloading", true);
  const { hasCachedObject, pdfsKey, videosKey } = self.cacheHelper;
  try {
    const items = mergeVideosAndPdfs({ videos, pdfs })
    for (let index = 0; index < items.length; index++) {
      const { vimeoId, pdf, type, idbKey } = items[index];
      const isCached = await hasCachedObject(idbKey, type);
      const isValid = isCached
        ? await checkIfFileIsValid(idbKey, type)
        : true;
      if (!isCached || !isValid) {
        if (type === videosKey) {
          await cacheVideo({ idbKey, vimeoId });
        }
        if (type === pdfsKey) {
          await cachePdf({ idbKey, url: pdf.url });
        }
      }
      const percent = ((index + 1) / items.length * 100).toFixed(0)
      store.commit("setDownloadProgress", percent);
    }
    if (isPwaInstalled) {
      alert(i18n.t("downloadservice_all_items_finished_downloading"));
      location.reload();
    }
  } catch (e) {
    store.commit("setDownloadingError", e.message);
    if (isPwaInstalled) {
      alert(i18n.t("downloadservice_download_failed"));
    }
  }
  setTimeout(() => {
    store.commit("setIsDownloading", false);
    store.commit("setDownloadProgress", 0);
    store.commit("setDownloadingError", null);
  }, 1000);
}

export async function checkAll() {
  const online = await isOnline();
  if (!online) {
    store.commit("setHasBackgroundCheckFinished", true);
    return;
  }
  const videos = store.getters.allVideos;
  const pdfs = store.getters.allDocuments;
  const items = mergeVideosAndPdfs({ videos, pdfs })
  try {
    const { hasCachedObject, pdfsKey, videosKey } = self.cacheHelper;
    for (let index = 0; index < items.length; index++) {
      const { vimeoId, pdf, type, idbKey } = items[index];
      const isCached = await hasCachedObject(idbKey, type);
      const isValid = isCached
        ? await checkIfFileIsValid(idbKey, type)
        : true;
      if (!isValid) {
        if (type === videosKey) {
          await cacheVideo({ idbKey, vimeoId });
        }
        if (type === pdfsKey) {
          await cachePdf({ idbKey, url: pdf.url });
        }
      }
    }
  }
  catch (e) {
    //eslint-disable-next-line
    console.error(e);
  }
  finally {
    store.commit("setHasBackgroundCheckFinished", true);
  }
}

export function hasCachedObject(key, storeName) {
  return self.cacheHelper.hasCachedObject(key, storeName);
}

export function createPdfUrl ({ key, url, language }) {
  const { createUrl, pdfsKey } = self.cacheHelper
  return createUrl({ key, url, language }, pdfsKey)
}

export function createVideoUrl ({ vimeoId }) {
  const { createUrl, videosKey } = self.cacheHelper
  return createUrl({ vimeoId }, videosKey)
}

export async function deleteCachedElementsForLanguageInDB(language) {
  const { deleteObject } = self.cacheHelper;
  const videos = { ...store.getters.getForCertainLanguage(language) };
  const pdfs = { ...store.getters.getPdfsForCertainLanguage(language) };
  const numberOfElementsToDelete =
    Object.keys(videos).length + Object.keys(pdfs).length;
  store.commit("setIsDeletingElements", true);
  store.commit("setSumOfElementsToDelete", numberOfElementsToDelete);
  //eslint-disable-next-line
  console.log(
    `[Caching] begin deleting of ${numberOfElementsToDelete} objects for language ${language}`
  );
  for (let video in videos) {
    const key = videos[video].path;
    await deleteObject(key, "videos");
    store.commit("incrementDeletedElements");
  }
  for (let pdf in pdfs) {
    const key = pdfs[pdf].path;
    await deleteObject(key, "pdfs");
    store.commit("incrementDeletedElements");
  }
  //eslint-disable-next-line
  console.log(
    `[Caching] finished deleting of ${numberOfElementsToDelete} objects for language ${language}`
  );
  setTimeout(() => {
    store.dispatch("setCachingStatus", ["de", "en"]);
    store.commit("resetDeletedElementsAndSumOdElements");
    store.commit("setIsDeletingElements", false);
  }, 1000);
}

export async function getPdfByURL(key) {
  const { hasCachedObject, getValueFromDb } = self.cacheHelper;
  var hasCache = await hasCachedObject(key, "pdfs");
  if (hasCache) {
    return getValueFromDb(key, "pdfs");
  }
}

export async function checkAllElementsForUpdate() {
  const online = await isOnline();
  if (!online) {
    return;
  }
  const videos = store.getters.allVideos;
  const pdfs = store.getters.allDocuments;
  const items = mergeVideosAndPdfs({ videos, pdfs })
  for (let i in items) {
    const element = items[i];
    const isVideo = element.elementtype === "videos";
    const isPdf = element.elementtype === "pdfs";
    const elementNeedsUpdate = await checkIfCachedElementNeedsToBeUpdated(
      element
    );
    if (elementNeedsUpdate) {
      //eslint-disable-next-line
      console.log(
        `[Caching] ${element.value.path} is outdated and will be updated`
      );
      if (isVideo) {
        cacheVideo(element.value);
      }
      if (isPdf) {
        cachePdf(element.value);
      }
    }
  }
}

async function checkIfCachedElementNeedsToBeUpdated({ value, elementtype }) {
  const { hasCachedObject, getObjectHeaders } = self.cacheHelper;
  const isCached = await hasCachedObject(value.path, elementtype);
  if (!isCached) {
    return false;
  }
  try {
    const isVideo = elementtype === "videos";
    const isPdf = elementtype === "pdfs";
    let modifyingDate = null;
    if (isVideo) {
      const response = await getVideoVersions(value.vimeoId);
      if (!response || !response.modifyingDate) {
        return false;
      }
      modifyingDate = Date.parse(response.modifyingDate);
    }
    if (isPdf) {
      const response = await getUrlHeaders(value.url);
      if (!response) {
        return false;
      }
      const headers = parseStringOfHeadersIntoArray(response);
      modifyingDate = getModifyingDateValue(headers, "date");
    }
    if (modifyingDate === null) {
      return false;
    }
    const cachedHeaders = await getObjectHeaders(value.path, elementtype);
    const cachingDate = getModifyingDateValue(cachedHeaders, "date");
    const needsUpdate = modifyingDate > cachingDate;
    return needsUpdate;
  } catch (e) {
    return false;
  }
}

async function checkIfFileIsValid(path, elementtype) {
  const { getObjectHeaders } = self.cacheHelper;
  const cachedHeaders = await getObjectHeaders(path, elementtype);
  const contentType = await findHeader(cachedHeaders, "content-type");
  if (
    (contentType.value == "video/mp4" && elementtype == "videos") ||
    (contentType.value == "application/pdf" && elementtype == "pdfs")
  ) {
    return true;
  }
  return false;
}

function findHeader(headers, key) {
  return headers.find(element => {
    return element.key && element.key.toLowerCase() === key.toLowerCase();
  });
}

function getModifyingDateValue(headers, key) {
  const header = findHeader(headers, key);
  if (header && header.value) {
    return Date.parse(header.value);
  }
  return null;
}

function parseStringOfHeadersIntoArray(response) {
  let headers = [];
  response.data.split("\n").forEach(element => {
    let splitetdElement = element.split(":");
    headers.push({ key: splitetdElement[0], value: splitetdElement[1] });
  });

  return headers;
}

export async function cacheVideo({ vimeoId, idbKey }) {
  try {
    //eslint-disable-next-line
    console.log(`[Caching] downloading video: ${vimeoId}`);
    await fetch(`download_video?id=${vimeoId}`);
    return {
      success: true
    };
  } catch (e) {
    const { deleteObject, videosKey } = self.cacheHelper;
    await deleteObject(idbKey, videosKey);
    handleError(e);
  }
}

async function cachePdf({ idbKey, url }) {
  try {
    const { cacheResponseToIdb, pdfsKey } = self.cacheHelper;
    //eslint-disable-next-line
    console.log(`[Caching] downloading document: ${idbKey} from ${url}`);
    const response = await getPdfByUrl(url);
    const result = await cacheResponseToIdb(idbKey, response, pdfsKey);
    return result;
  } catch (e) {
    const { deleteObject, pdfsKey } = self.cacheHelper;
    await deleteObject(idbKey, pdfsKey);
    handleError(e);
  }
}

function handleError(e) {
  if (e.name === "TypeError" && e.message === "Failed to fetch") {
    throw new Error("typeError_failed_to_fetch");
  } else {
    throw e;
  }
}
