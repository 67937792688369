<template>
  <div class="settings-download-progress">
    <button v-if="!isDownloading" @click="performDownload">
      <p class="settings-download-progress__text">
        {{ $t("install_guide_perform_download") }}
      </p>
    </button>
    <div v-else>
      <p class="settings-download-progress__text">
        {{ $i18n.t("downloadprogress_downloadtime_notification") }}
      </p>
      <p class="settings-download-progress__text">
        {{ $i18n.t("downloadprogress_indicator", [downloadProgress]) }}
      </p>
    </div>
  </div>
</template>

<script>
import { getAll } from "@/services/caching/cachingHelpers";
export default {
  name: "DownloadProgress",
  computed: {
    isDownloading() {
      return this.$store.state.isDownloading;
    },
    downloadProgress() {
      return this.$store.state.downloadProgress;
    },
  },
  methods: {
    performDownload() {
      const videos = this.$store.getters.allVideos;
      const pdfs = this.$store.getters.allDocuments;
      getAll({ videos, pdfs });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/";
.settings-download-progress {
  @include padding-v(12px);
  button {
    font-family: $font_nettoRegular;
    text-transform: uppercase;
    color: $color_erbeBlue;
    font-size: 14px;
    line-height: 16px;
    height: 24px;
  }
  &__text {
    font-family: $font_nettoRegular;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
