<template>
  <footer class="footer">
    <div class="footer-links">
      <a
        class="footer-links-item"
        v-for="item in linkItems"
        :key="item.title"
        :href="item.href"
        :title="item.title"
      >
        <img :src="item.icon" />
        <span>{{ item.title.toUpperCase() }}</span>
      </a>
    </div>
    <div class="footer-info">
      <span class="footer-info-item">{{
        $i18n.t("footer_address_part1")
      }}</span>
      <span class="footer-info-dot"> · </span>
      <span class="footer-info-item">{{
        $i18n.t("footer_address_part2")
      }}</span>
      <span class="footer-info-dot"> · </span>
      <span class="footer-info-item">{{
        $i18n.t("footer_address_part3")
      }}</span>
      <span class="footer-info-dot"> · </span>
      <span class="footer-info-item">{{
        $i18n.t("footer_address_part4")
      }}</span>
      <span class="footer-info-dot"> · </span>
      <a href="tel:+4970717550" title="Telephone">+49 7071 755-0</a>
      <span class="footer-info-dot"> · </span>
      <a href="mailto:info@erbe-med.com" title="Email">info@erbe-med.com</a>
    </div>
    <div class="footer-social">
      <span class="footer-social-prefix">{{
        $i18n.t("footer_social_prefix").toUpperCase()
      }}</span>
      <a
        v-for="item in socialItems"
        :key="item.title"
        class="footer-social-item"
        :href="item.href"
        :title="item.title"
      >
        <img :src="item.icon" />
      </a>
    </div>
    <div class="footer-nav">
      <nav class="footer-nav-menu">
        <ul class="footer-nav-menu-items">
          <li
            class="footer-nav-menu-item"
            v-for="item in navItems"
            :key="item.title"
          >
            <a
              class="footer-nav-menu-item-link"
              :href="item.href"
              :title="item.title"
            >
              {{ item.title }}
            </a>
          </li>
        </ul>
      </nav>
      <div class="footer-nav-copyright">
        <p class="footer-nav-copyright-text">
          {{ $i18n.t("footer_nav_copyright") }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  computed: {
    linkItems() {
      return [
        {
          icon: "/svg/footer_icon_events.svg",
          href: "https://de.erbe-med.com/de-en/education/events/",
          title: this.$i18n.t("footer_link_events"),
        },
        {
          icon: "/svg/footer_icon_catalog.svg",
          href: "https://de.erbe-med.com/de-en/landingpages/catalog/",
          title: this.$i18n.t("footer_link_catalog"),
        },
        {
          icon: "/svg/footer_icon_mobileapps.svg",
          href: "https://de.erbe-med.com/de-en/landingpages/mobile-apps/",
          title: this.$i18n.t("footer_link_mobile_apps"),
        },
        {
          icon: "/svg/footer_icon_contact.svg",
          href: "https://de.erbe-med.com/de-en/company/contact/",
          title: this.$i18n.t("footer_link_contact"),
        },
      ];
    },
    socialItems() {
      return [
        {
          icon: "/svg/footer_erbe_logo.svg",
          href: "https://www.erbe-med.com/",
          title: this.$i18n.t("footer_social_homepage"),
        },
        {
          icon: "/svg/footer_medicalvideos_logo.svg",
          href: "https://www.medical-videos.com/",
          title: this.$i18n.t("footer_social_videos"),
        },
        {
          icon: "/svg/footer_linkedin_logo.svg",
          href: "https://www.linkedin.com/company/erbe-elektromedizin/",
          title: this.$i18n.t("footer_social_linkedin"),
        },
        {
          icon: "/svg/footer_youtube_logo.svg",
          href: "https://www.youtube.com/user/erbemed/",
          title: this.$i18n.t("footer_social_youtube"),
        },
      ];
    },
    navItems() {
      return [
        {
          href: "https://de.erbe-med.com/de-en/meta-navigation/imprint/",
          title: this.$i18n.t("footer_nav_menu_item1"),
        },
        {
          href: "https://de.erbe-med.com/de-en/meta-navigation/privacy-policy/",
          title: this.$i18n.t("footer_nav_menu_item2"),
        },
        {
          href: "https://erbe-med.com/fileadmin/pdf/company/Terms_of_use_EN.pdf",
          title: this.$i18n.t("footer_nav_menu_item3"),
        },
        {
          href: "https://de.erbe-med.com/de-en/meta-navigation/cookies/",
          title: this.$i18n.t("footer_nav_menu_item4"),
        },
        {
          href: "https://de.erbe-med.com/de-en/meta-navigation/terms-of-trade/",
          title: this.$i18n.t("footer_nav_menu_item5"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.footer {
  @include flex-v(center, center);
  @include padding-h(60px);
  padding-top: 6rem;
  padding-bottom: 50px;
  background: white;
  font-family: $font_nettoRegular;
  &-links {
    @include flex-h(center, center);
    width: 100%;
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    @include breakpoint(xs) {
      @include flex-h(center, flex-start);
      flex-direction: column;
    }
    &-item {
      @include flex-v(center, center);
      img {
        margin-bottom: 12px;
        @include size(60px);
        @include breakpoint(xs) {
          @include size(40px);
          margin-bottom: 0;
        }
      }
      @include margin-h(15px);
      @include breakpoint(xs) {
        @include margin-h(0px);
        flex-direction: row;
      }
      span {
        font-family: $font_nettoBlack;
        text-align: center;
        @include text(18px, 14px, 1.2px);
        @include margin-h(6px);
      }
    }
  }
  &-info {
    @include flex-h(center, center);
    @include padding-h(21px);
    @include text(16px, 24px, 0);
    text-align: center;
    color: $text_dark;
    width: 100%;
    @include breakpoint(xs) {
      display: block;
    }
    @include breakpoint(s) {
      display: block;
    }
    a {
      color: $text_dark;
      &:hover {
        color: $color_erbeBlue;
      }
    }
    &-dot {
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
  &-social {
    @include flex-h(space-between, center);
    margin-top: 40px;
    @include breakpoint(xs) {
      margin-top: 20px;
    }
    &-prefix {
      font-family: $font_nettoBlack;
      margin-right: 12px;
      @include text(14px, 24px, 1.2px);
    }
    &-item {
      @include flex-h(center, center);
      @include margin-h(6px);
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
  &-nav {
    width: 100%;
    @include flex-h(space-between, center);
    margin-top: 6rem;
    flex-direction: row-reverse;
    @include breakpoint(xs) {
      flex-direction: column;
      margin-top: 2rem;
    }
    &-copyright {
      font-family: $font_nettoRegular;
      font-size: 14px;
      @include breakpoint(xs) {
        margin-top: 40px;
      }
    }
    &-menu {
      &-items {
        @include flex-h(space-between, flex-end);
        @include breakpoint(xs) {
          flex-direction: column;
          align-items: center;
        }
      }
      &-item {
        margin-left: 16px;
        @include breakpoint(xs) {
          margin-top: 10px;
        }
        &-link {
          font-family: $font_nettoBlack;
          font-size: 14px;
          padding: 8px;
          color: $color_erbeBlue;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
        @include breakpoint(xs) {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
