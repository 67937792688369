<template>
  <section id="introduction" class="intro">
    <div class="intro-text">
      <h1 class="intro-text-headline">
        <i18n path="introduction_header"></i18n>
      </h1>
      <p class="intro-text-subheadline">
        <i18n path="introduction_paragraph"></i18n>
      </p>
    </div>
    <div class="pointer-links">
      <img class="intro-image" :src="styleImgLungIllustration" />
      <PointerLink
        alignment="left"
        class="pointer-link-1"
        name="accordion_headline1"
        y="36"
        x="49"
        @click.native="scrollCollapseToElement('obstruction')"
      />
      <PointerLink
        alignment="left"
        class="pointer-link-2"
        name="accordion_headline2"
        y="43"
        x="55"
        @click.native="scrollCollapseToElement('pulmonary')"
      />
      <PointerLink
        alignment="right"
        class="pointer-link-3"
        name="accordion_headline3"
        y="61"
        x="65"
        @click.native="scrollCollapseToElement('interstitial')"
      />
      <PointerLink
        alignment="right"
        class="pointer-link-4"
        name="accordion_headline4"
        y="68"
        x="65"
        @click.native="scrollCollapseToElement('peripheral')"
      />
      <PointerLink
        alignment="right"
        class="pointer-link-5"
        name="accordion_headline5"
        y="52"
        x="56"
        @click.native="scrollCollapseToElement('aspiration')"
      />
      <PointerLink
        alignment="right"
        class="pointer-link-6"
        name="accordion_headline6"
        y="44"
        x="53"
        @click.native="scrollCollapseToElement('benign')"
      />
      <PointerLink
        alignment="left"
        class="pointer-link-7"
        name="accordion_headline7"
        y="64"
        x="66"
        @click.native="scrollCollapseToElement('exsudative')"
      />
    </div>
  </section>
</template>

<script>
import PointerLink from "@/components/PointerLink";
import { scrollToElementById } from "@/services/scroll.js";

export default {
  name: "IntroductionSection",
  components: {
    PointerLink,
  },
  methods: {
    scrollCollapseToElement(id) {
      scrollToElementById(id);
      this.collapseScrolledToSection(id);
    },
    collapseScrolledToSection(id) {
      this.$store.commit("setCollapseStateToTrue", id);
    },
  },
  computed: {
    styleImgLungIllustration() {
      return this.$store.getters.img_lung_illustration;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";
.intro {
  background: -webkit-radial-gradient(circle center 120%, white, black);
  background: radial-gradient(circle at center 120%, white, black);
  height: calc(125vh - $navBarHeight);
  margin-top: $navBarHeight;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  text-align: center;
  width: 100%;
  @include breakpoint(xs) {
    height: calc(100vh - $navBarHeightXS);
    margin-top: $navBarHeightXS;
  }
  @include breakpoint(s) {
    height: calc(100vh - $navBarHeightS);
    margin-top: $navBarHeightS;
  }
  &-text {
    padding-top: 40px;
    position: relative;
    width: 100%;
    z-index: 1;
    @include breakpoint(xs) {
      padding-top: 30px;
    }
    @include breakpoint(s) {
      padding-top: 20px;
    }
    &-headline {
      @include ts-section-headline();
      color: $color_erbeBlue;
      margin-bottom: 20px;
      @include breakpoint(s) {
        margin-bottom: 15px;
      }
      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }
    &-subheadline {
      @include ts-section-subheadline();
      color: $color_erbeBlue;
    }
  }
  .pointer-links {
    height: auto;
    width: 1920px;
    left: 50%;
    position: absolute;
    top: 48%;
    transform: translate(-50%, -50%);
    @include breakpoint(s) {
      width: 1440px;
      top: 50%;
      .pointer-link {
        &-3 {
          flex-direction: row;
          transform: translateX(65%);
          text-align: left;
        }
        &-4 {
          flex-direction: row;
          transform: translateX(65%);
          text-align: left;
        }
        &-7 {
          flex-direction: row-reverse;
          transform: translateX(-66%);
          text-align: right;
        }
      }
    }
    @include breakpoint(xs) {
      width: 960px;
      top: 50%;
      .pointer-link {
        &-3 {
          flex-direction: row;
          transform: translateX(70%);
          text-align: left;
        }
        &-4 {
          flex-direction: row;
          transform: translateX(70%);
          text-align: left;
        }
        &-7 {
          flex-direction: row-reverse;
          transform: translateX(-75%);
          text-align: right;
        }
      }
    }
  }
  &-image {
    height: auto;
    position: relative;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
}
</style>