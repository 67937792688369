<template>
  <div class="background" :class="[promptType]">
    <p>
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Sending",
  props: {
    promptType: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      default: "Sending...",
    },
  },
};
</script>

<style scoped>
.background {
  display: flex;
  margin: auto;
  height: 48px;
  color: white;
  border-radius: 0.3rem;
  vertical-align: "center";
  padding: 0 20px;
}
p {
  margin: auto;
}
.success {
  background-color: #a4fab8;
}
.failure {
  background-color: #ffa4ae;
}
.sending {
  background-color: #17a2b8;
}
</style>