import Vue from "vue";
import VueI18n from "vue-i18n";
import de from "./de.json";
import en from "./en.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: window.location.hash.split("/")[1],
  fallbackLcale: "en",
  messages: {
    de,
    en,
  },
});
