<template>
  <div class="pulmonary-lesions-section">
    <span class="accordion-title">
      {{ $t("accordion_peripheral_pulmonary_title") }}
    </span>
    <AccordionVideoContainer :videoIds="['605506909']" />
    <ListWithArrows
      :entriesLeft="[
        'accordion_pulmonary_lesions_arrow_list_1',
        'accordion_pulmonary_lesions_arrow_list_2',
        'accordion_pulmonary_lesions_arrow_list_3',
      ]"
      :entriesRight="[
        'accordion_pulmonary_lesions_arrow_list_4',
        'accordion_pulmonary_lesions_arrow_list_5',
      ]"
    />
    <ScientificInformationCardRow
      :count="1"
      prefix="accordion_pulmonary_lesions"
    />
    <ProductsForIntervention
      :leftItems="['20402-410', '20402-401', '20402-402']"
      :leftInformation="[
        'accordion_pulmonary_lesions_products_information_1',
        'accordion_pulmonary_lesions_products_information_2',
      ]"
      :leftButtonLink="
        'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en'
      "
    />
  </div>
</template>

<script>
import ScientificInformationCardRow from "@/components/ScientificInformationCardRow";
import ListWithArrows from "../../components/ListWithArrows.vue";
import AccordionVideoContainer from "../../components/AccordionVideoContainer.vue";
import ProductsForIntervention from "../../components/ProductsForIntervention.vue";

export default {
  name: "PulmonaryLesionsSection",
  components: {
    ScientificInformationCardRow,
    ListWithArrows,
    AccordionVideoContainer,
    ProductsForIntervention,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
.pulmonary-lesions-section {
  display: flex;
  flex-direction: column;
  font-family: $font_nettoRegular;
  background-color: lightgray;
  background: -webkit-radial-gradient(circle center 50%, lightgray, gray);
  background: radial-gradient(circle at center 50%, lightgray, gray);
  align-items: center;
  cursor: default;
  padding-top: 1rem;
}
</style>
