<template>
  <div class="container">
    <span class="title">{{ $t("accordion_card_headline") }}</span>
    <div class="card-row">
      <div v-for="index in count" :key="index">
        <ScientificInformationCard :title="prefix + '_scientific_card_' + index + '_title'"
          :subtitle="prefix + '_scientific_card_' + index + '_subtitle'"
          :text="prefix + '_scientific_card_' + index + '_text'"
          :scientificPaperReference="prefix + '_scientific_card_' + index + '_scientific_paper_reference'"
          :type="prefix + '_scientific_card_' + index + '_type'" :href="prefix + '_scientific_card_' + index + '_href'"
          :pdfName="prefix + '_scientific_card_' + index" />
      </div>
    </div>
  </div>
</template>

<script>
import ScientificInformationCard from "./ScientificInformationCard.vue";
export default {
  name: "ScientificInformationCardRow",
  components: {
    ScientificInformationCard,
  },
  props: {
    count: Number,
    prefix: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";

.title {
  font-size: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: radial-gradient(circle, rgba(107, 151, 136, 0.7) 0%, rgba(97, 175, 147, 0.95) 33%, rgba(87, 142, 123, 0.95) 71%, rgba(73, 127, 107, 0.95) 100%);
  width: 100%;
  padding: 40px;
}

.card-row {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 10px;
}
</style>
