export function createSources(x1, x2) {
  return {
    src: x1,
    srcset: `${x1} 1x, ${x2} 2x`,
    imageset: `url(${x1}) 1x, url(${x2}) 2x`,
  };
}
export const createImagesModule = () => {
  return {
    state: {},
    getters: {
      img_lung_illustration: () => {
        return require("@/assets/images/Lung-Illustration_transparent.png");
      },
      img_workstation: () => {
        return require("@/assets/images/Workstation-pulmonology.jpg");
      },
      img_multimedia: () => {
        return require("@/assets/images/Multimedia.jpg");
      },
      img_testimonial_face1: () => {
        return require("@/assets/images/Testimonial-1.jpg");
      },
      img_product_intervention: () => (imageKey) => {
        console.warn(imageKey)
        return require("@/assets/images/" + imageKey);
      }
    },
  };
};