<template>
  <div class="obstructing-section">
    <span class="accordion-title">
      {{ $t("accordion_obstructing_title") }}
    </span>
    <AccordionVideoContainer
      :videoIds="[
        '312685773',
        '440014204',
        '355305179',
        '690961577',
        '696924990',
        '696925135',
        '696925231',
        '214857781',
      ]"
    />
    <ListWithArrows
      :entriesLeft="[
        'accordion_obstructing_arrow_list_1',
        'accordion_obstructing_arrow_list_2',
        'accordion_obstructing_arrow_list_3',
        'accordion_obstructing_arrow_list_4',
      ]"
      :entriesRight="[
        'accordion_obstructing_arrow_list_5',
        'accordion_obstructing_arrow_list_6',
        'accordion_obstructing_arrow_list_7',
      ]"
    />
    <ScientificInformationCardRow :count="2" prefix="accordion_obstructing" />
    <ProductsForIntervention
      :leftItems="['20402-411', '20402-410', '20402-401', '20402-402']"
      :leftInformation="[
        'accordion_obstructing_products_information_1',
        'accordion_obstructing_products_information_2',
        'accordion_obstructing_products_information_3',
        'accordion_obstructing_products_information_4',
        'accordion_obstructing_products_information_5',
      ]"
      :leftButtonLink="
        'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en'
      "
      :rightItems="['20132-220', '20132-221']"
      :rightInformation="[
        'accordion_obstructing_products_information_6',
        'accordion_obstructing_products_information_7',
        'accordion_obstructing_products_information_8',
      ]"
      :rightButtonLink="
        'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DFiAPC&language=en'
      "
    />
  </div>
</template>

<script>
import ScientificInformationCardRow from "@/components/ScientificInformationCardRow";
import ListWithArrows from "../../components/ListWithArrows.vue";
import AccordionVideoContainer from "../../components/AccordionVideoContainer.vue";
import ProductsForIntervention from "../../components/ProductsForIntervention.vue";

export default {
  name: "ObstructingSection",
  components: {
    ScientificInformationCardRow,
    ListWithArrows,
    AccordionVideoContainer,
    ProductsForIntervention,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss";
.obstructing-section {
  display: flex;
  flex-direction: column;
  font-family: $font_nettoRegular;
  background-color: lightgray;
  background: -webkit-radial-gradient(circle center 50%, lightgray, gray);
  background: radial-gradient(circle at center 50%, lightgray, gray);
  align-items: center;
  cursor: default;
  padding-top: 1rem;
}
</style>
