<template>
  <section id="testimonials" class="testimonials">
    <div class="content-padder">
      <div class="testimonials__slider">
        <div class="testimonials__slider-slide">
          <p class="testimonials__slider-slide-quote">
            „{{ $t("testimonial_1") }}”
          </p>
          <div class="testimonials__slider-slide-person">
            <div class="testimonials__slider-slide-person-portrait-container">
              <div
                class="
                  testimonials__slider-slide-person-portrait-container-cutout
                "
              >
                <img
                  class="
                    testimonials__slider-slide-person-portrait-container-cutout-image
                  "
                  :src="styleImgTestimonials"
                />
              </div>
            </div>
            <div class="testimonials__slider-slide-person-namewrapper">
              <p class="testimonials__slider-slide-person-namewrapper-name">
                {{ $t("testimonial_1_name") }}
              </p>
              <p class="testimonials__slider-slide-person-namewrapper-position">
                {{ $t("testimonial_1_occupation") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TestimonialsSection",
  computed: {
    styleImgTestimonials() {
      return this.$store.getters.img_testimonial_face1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.testimonials {
  background-color: rgb(0, 171, 189);
  &__header {
    color: $text_dark;
    text-align: center;
    @include padding-v(30px);
    @include ts-section-headline();
  }
  &__slider {
    color: white;
    padding-bottom: 15px;
    &-slide {
      padding: 50px 80px;
      @include flex-v(center, center);
      text-align: center;
      @include breakpoint(xs) {
        padding: 30px 30px;
        max-width: 100%;
      }
      &-quote {
        font-family: $font_nettoRegular;
        color: white;
        max-width: 80%;
        @include text(32px, 36px, 0.4px);
        @include breakpoint(xs) {
          @include text(16px, 24px, 0.16px);
          max-width: 100%;
        }
        @include breakpoint(s) {
          @include text(16px, 24px, 0.16px);
        }
      }
      &-person {
        @include flex-h(center, center);
        width: 60%;
        margin-top: 50px;
        @include breakpoint(xs) {
          @include flex-v(center, center);
          width: 90%;
          margin-top: 30px;
        }
        @include breakpoint(s) {
          width: 80%;
          margin-top: 30px;
        }
        &-portrait-container {
          @include breakpoint(xs) {
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
          }
          &-cutout {
            float: right;
            width: 200px;
            height: 200px;
            overflow: hidden;
            margin-right: 20px;
            border-radius: 50%;

            @include breakpoint(xs) {
              float: none;
              width: 80px;
              height: 80px;
            }
            @include breakpoint(s) {
              width: 100px;
              height: 100px;
            }
            &-image {
              width: 200px;
              @include breakpoint(xs) {
                width: 80px;
              }
              @include breakpoint(s) {
                width: 100px;
              }
            }
          }
        }
        &-namewrapper {
          flex: 1;
          @include flex-v(flex-start, flex-start);
          text-align: left;
          @include breakpoint(xs) {
            @include flex-v(center, center);
            text-align: center;
            flex: inherit;
            width: 80%;
          }

          &-name {
            font-family: $font_nettoBold;
            @include text(24px, 30px, 0.24px);
            @include breakpoint(s) {
              @include text(14px, 21px, 0.14px);
            }
            @include breakpoint(xs) {
              text-align: center;
              @include text(12px, 18px, 0.12px);
            }
          }
          &-position {
            font-family: $font_nettoRegular;
            max-width: 100%;
            @include text(24px, 30px, 0.24px);
            @include breakpoint(xs) {
              text-align: center;
              @include text(12px, 18px, 0.12px);
              @include ie_or_edge() {
                max-width: 80%;
              }
            }
            @include breakpoint(s) {
              @include text(14px, 21px, 0.14px);
            }
          }
        }
      }
    }
  }
}
</style>
