var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pulmonary-lesions-section"},[_c('span',{staticClass:"accordion-title"},[_vm._v(" "+_vm._s(_vm.$t("accordion_peripheral_pulmonary_title"))+" ")]),_c('AccordionVideoContainer',{attrs:{"videoIds":['605506909']}}),_c('ListWithArrows',{attrs:{"entriesLeft":[
      'accordion_pulmonary_lesions_arrow_list_1',
      'accordion_pulmonary_lesions_arrow_list_2',
      'accordion_pulmonary_lesions_arrow_list_3' ],"entriesRight":[
      'accordion_pulmonary_lesions_arrow_list_4',
      'accordion_pulmonary_lesions_arrow_list_5' ]}}),_c('ScientificInformationCardRow',{attrs:{"count":1,"prefix":"accordion_pulmonary_lesions"}}),_c('ProductsForIntervention',{attrs:{"leftItems":['20402-410', '20402-401', '20402-402'],"leftInformation":[
      'accordion_pulmonary_lesions_products_information_1',
      'accordion_pulmonary_lesions_products_information_2' ],"leftButtonLink":'https://de.erbe-med.com/productfinder/index.php?openurl=PLM_PRODUKTKATEGORIE%3DInstrument%7CPLM_PRODUKTGRUPPE%3DFlexible%20Sonden%7CPLM_PRODUKTUNTERGRUPPE%3DERBECRYO%202&language=en'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }