<template>
  <section id="workstation" class="workstation">
    <img class="workstation-image" :src="styleImgWorkstation" />
    <div class="workstation-text">
      <h2 class="workstation-text-headline">
        <i18n path="workstation_header"></i18n>
      </h2>
      <p class="workstation-text-subheadline">
        <i18n path="workstation_paragraph" />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "WorkstationSection",
  data() {
    return {};
  },
  computed: {
    styleImgWorkstation() {
      return this.$store.getters.img_workstation;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss";
.workstation {
  height: calc(100vh - $navBarHeight);
  position: relative;
  overflow: hidden;
  &-image {
    height: auto;
    width: 100%;
    min-width: 1920px;
    left: 50%;
    position: absolute;
    top: -10%;
    transform: translate(-50%, 0);
    @include breakpoint(xs) {
      height: calc(100vh - $navBarHeightXS);
      min-width: 1440px;
      top: 0;
    }
    @include breakpoint(s) {
      height: calc(100vh - $navBarHeightS);
      min-width: 1440px;
      top: 0;
    }
  }
  &-text {
    @include padding-h(60px);
    @include padding-v(60px);
    position: relative;
    width: 100%;
    z-index: 1;
    text-align: center;
    &-headline {
      @include ts-section-headline();
      color: $text_dark;
      margin-bottom: 20px;
      @include breakpoint(s) {
        margin-bottom: 15px;
      }
      @include breakpoint(xs) {
        margin-bottom: 10px;
      }
    }
    &-subheadline {
      @include ts-section-subheadline();
      color: $text_dark;
      text-shadow: 0px 0px 3px #FFFFFFBB;

    }
  }
  &-content {
    @include padding-h(60px);
    @include ts-section-subheadline();
    color: $text_light;
    margin-top: 8rem;
    margin-left: 50%;
    position: relative;
    width: 50%;
  }
}
</style>