<template>
  <div>
    <video
      v-if="serviceWorkerReady === true"
      class="video-offline"
      :class="{ 'video-offline--full': isFullScreen }"
      controls
      :key="vimeoId"
    >
      <source :src="`/vimeo_${vimeoId}.mp4?id=${vimeoId}`" type="video/mp4" />
    </video>
    <div v-else class="video-offline"></div>
  </div>
</template>

<script>
export default {
  name: "VideoOffline",
  data() {
    return {
      isPlaying: false,
      errorDismissed: false,
    };
  },
  props: {
    vimeoId: String,
    isFullScreen: Boolean,
    autoplay: Boolean,
  },
  watch: {
    serviceWorkerReady() {
      this.setSources();
    },
  },
  computed: {
    serviceWorkerReady() {
      return this.$store.state.serviceWorkerReady;
    },
  },
  methods: {
    onPlay() {
      this.isPlaying = true;
    },
    onLog(e) {
      if (e.type !== "loadstart" && e.type !== "loadeddata") {
        //eslint-disable-next-line
        console.log(e);
      }
    },
    onError(e) {
      if (!this.errorDismissed) {
        window.alert(e.message);
        this.errorDismissed = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss";
.video-offline {
  background-color: black;
  &--full {
    height: calc(56.25vw);
    object-fit: fill;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border: 0;
  }
  height: 100%;
  width: 100%;
}
</style>
